<script setup>
import { useUploadMediaStore } from '~/stores/account/upload-media.store';
import { useUserStore } from '~/stores/user.store';
import helper from '~/resources/js/libraries/helper';

const uploadMediaStore = useUploadMediaStore();
const userStore = useUserStore();

const user = computed(() => userStore.userGetter());
const fileInput = ref(null);
const loading = ref({
  base64: '',
  name: '',
  progress: 0,
});
const errMsg = ref('');
const uploadedImages = ref([]);


const triggerFileInput = () => {
  fileInput.value.click();
};



const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const saveFile = async (event) => {
  const file = event.target.files[0];
  let base64 = await fileToBase64(file);

  loading.value = {
    name: Math.random().toString(16).slice(2),
    base64: base64,
    progress: 0
  }

  const base64Resized = await helper.resizeImage(loading.value.base64);

  const formData = { "image": base64Resized, };

  let data = JSON.stringify(formData);
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': 'application/json',
      'validate-height': 500,
      'validate-width': 500,
    },
    data: data,
    onUploadProgress: (progressEvent) => {
      let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      loading.value.progress = progress;
    },
  };

  await uploadMediaStore.uploadMedia(config)
    .then(async (res) => {
      uploadedImages.value.push(res.data.file?.toString());
      if (uploadedImages.value.length > 0) {
        const res = await userStore.changePicture(uploadedImages.value[0]);
        if (res.status !== 200) return;
        await userStore.getUserData();
      }
    })
    .catch(err => {
      errMsg.value = err?.response?.data?.message || 'Unexpected error occurred. Please try again.';
    })
    .finally(() => {
      loading.value.progress = 0;
    });
};
</script>
<template>
  <div class="my-6 flex flex-col justify-center gap-5 items-center cursor-pointer" @click="triggerFileInput">
    <div class="relative flex flex-col justify-center">
      <div>
        <img class="w-16 h-16 rounded-full me-3" :src="user.picture" :alt="`${user.first_name} ${user.last_name}`">
      </div>
      <div class="cursor-pointer">
        <i
          class="fa-solid fa-camera text-white absolute top-0 right-0 w-8 h-8 leading-8 text-center rounded-full bg-indigo-800 block border border-[#f5f5f5]"></i>
      </div>
      <input ref="fileInput" type="file" class="hidden" @change="saveFile">
    </div>
  </div>
</template>